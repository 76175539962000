import Head from 'next/head';
import { omittedDescription } from '../../modules/tool';
import { baseMetaDescription } from '@/modules/config/meta';

const links = [
    {
        rel: 'icon',
        href: '/meta/favicon.ico'
    },
    {
        rel: 'icon',
        type: 'image/svg+xml',
        href: '/meta/favicon.svg'
    },
    {
        rel: 'apple-touch-icon',
        href: '/meta/apple-touch-icon.png'
    },
    {
        rel: 'manifest',
        href: '/meta/manifest.json'
    }
];

const BASE_URL = process.env.NEXT_PUBLIC_ROOT_URL || 'http://localhost:3002/';
type MetaData = {
    type?: 'website' | 'article';
    title?: string;
    description?: string;
    site_name?: string;
    image?: string;
};

const defaultMetaData: Required<MetaData> = {
    type: 'website',
    title: 'フリーコンサル案件紹介 ProConnect（プロコネクト）｜仕事紹介プラットフォーム',
    description: baseMetaDescription,
    site_name: 'ProConnect（プロコネクト）',
    image: `meta/ogp.webp`
};

const removeHtmlTag = (html: string) => html.replace(/(<([^>]+)>)/gi, '');

function Seo({ type, title, description, site_name, image }: MetaData) {
    const metadata: Required<MetaData> = {
        type: type || defaultMetaData.type,
        title: title || defaultMetaData.title,
        description: removeHtmlTag(description || defaultMetaData.description),
        site_name: site_name || defaultMetaData.site_name,
        image: `${BASE_URL}${image || defaultMetaData.image}`
    };

    return (
        <Head>
            <meta charSet="utf-8" />
            <meta
                content="width=device-width, initial-scale=1"
                name="viewport"
            />
            <base href="./" />
            {/* SEO SETTINGS */}
            <title>{metadata.title}</title>
            <meta
                name="description"
                content={omittedDescription(metadata.description)}
            />
            <meta
                name="keywords"
                content="ProConnect,プロコネクト,フリーランス,コンサルタント,案件紹介"
            />
            <meta httpEquiv="X-UA-Compatible" content="IE=Edge" />

            {/* Favicon */}
            {links.map((link, i) => (
                <link key={i} {...link} />
            ))}
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta
                name="msapplication-TileImage"
                content="/meta/ms-icon-144x144.png"
            />
            <meta name="theme-color" content="#ffffff" />
            {/* OGP */}
            <meta property="og:type" content={metadata.type} />
            <meta property="og:site_name" content={metadata.site_name} />
            <meta property="og:title" content={metadata.title} />
            <meta
                property="og:description"
                content={omittedDescription(metadata.description)}
            />
            <meta property="og:image" content={metadata.image} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="ProConnect（プロコネクト）" />
            <meta name="twitter:title" content={metadata.title} />
            <meta
                name="twitter:description"
                content={omittedDescription(metadata.description)}
            />
            <meta name="twitter:image:src" content={metadata.image} />
            {/* facebook domain verification */}
            <meta
                name="facebook-domain-verification"
                content="2hzlsg70oel2eqg9jx7trwij1n1ncm"
            />
        </Head>
    );
}

export default Seo;
